import { TabPanels } from '@reach/tabs';
import ResponseStatusTabs from "../../../../src/components/blocks/ResponseStatusTabs";
import TabItem from "../../../../src/components/blocks/TabBar/TabItem";
import TabBar from "../../../../src/components/blocks/TabBar/TabBar";
import CodeBlock from "../../../../src/components/blocks/CodeEditor/CodeBlock";
import HeaderRequestLink from "../../../../src/components/fragments/HeaderRequestLink";
import { StyledTab, StyledTabPanel, StyledTabs, StyledTabList } from "../../../../src/components/fragments/StyledTabs";
import SchemeModal from "../../../../src/components/blocks/StructureScheme";
import * as React from 'react';
export default {
  TabPanels,
  ResponseStatusTabs,
  TabItem,
  TabBar,
  CodeBlock,
  HeaderRequestLink,
  StyledTab,
  StyledTabPanel,
  StyledTabs,
  StyledTabList,
  SchemeModal,
  React
};