import { TabPanels } from '@reach/tabs';
import ResponseStatusTabs from "../../../../src/components/blocks/ResponseStatusTabs";
import TabItem from "../../../../src/components/blocks/TabBar/TabItem";
import TabBar from "../../../../src/components/blocks/TabBar/TabBar";
import CodeEditor from "../../../../src/components/blocks/CodeEditor/CodeEditor";
import HeaderRequestLink from "../../../../src/components/fragments/HeaderRequestLink";
import * as React from 'react';
export default {
  TabPanels,
  ResponseStatusTabs,
  TabItem,
  TabBar,
  CodeEditor,
  HeaderRequestLink,
  React
};