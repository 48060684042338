import ResponseStatusTabs from "../../../../src/components/blocks/ResponseStatusTabs";
import TabItem from "../../../../src/components/blocks/TabBar/TabItem";
import TabBar from "../../../../src/components/blocks/TabBar/TabBar";
import CodeEditor from "../../../../src/components/blocks/CodeEditor/CodeEditor";
import HeaderRequestLink from "../../../../src/components/fragments/HeaderRequestLink";
import SchemeModal from "../../../../src/components/blocks/StructureScheme";
import * as React from 'react';
export default {
  ResponseStatusTabs,
  TabItem,
  TabBar,
  CodeEditor,
  HeaderRequestLink,
  SchemeModal,
  React
};