// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-solutions-jsx": () => import("./../src/pages/about/solutions.jsx" /* webpackChunkName: "component---src-pages-about-solutions-jsx" */),
  "component---src-pages-docs-api-api-list-jsx": () => import("./../src/pages/docs/api/api-list.jsx" /* webpackChunkName: "component---src-pages-docs-api-api-list-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-templates-docs-jsx": () => import("./../src/templates/docs.jsx" /* webpackChunkName: "component---src-templates-docs-jsx" */),
  "component---src-templates-docsmdx-jsx": () => import("./../src/templates/docsmdx.jsx" /* webpackChunkName: "component---src-templates-docsmdx-jsx" */)
}

