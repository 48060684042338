export default {
  name: 'Form',
  fields: [
    {
      name: 'formFile',
      type: 'string($binary)',
      description: 'File content'
    }
  ],
  example: ''
};
